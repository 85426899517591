.layout-content-inner {
  padding: 15px;
  flex: 1;
}

.app-footer {
  background-color: #fff;
  color: $textColorDarken;
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
  font-weight: 300;
  font-size: 14px;
}
