.general-search {
  display: flex;
  height: 38px;
  margin: 0 5px;
  position: relative;

  .search-field-group {
    opacity: 0;
    width: 0;
    overflow: hidden;
  }

  .icon-link.white {
    transition: $transition;
  }

  @media #{$media-down-sm} {
    display: none;
  }

  .hide-search {
    position: absolute;
    z-index: 2;
    left: 11px;
    top: 14px;
    font-size: 10px;
    cursor: pointer;
    color: $grey-darken;
  }

  &.open {
    flex: 1;
    max-width: 600px;

    .icon-link.white {
      opacity: 0;
    }

    .search-field-group {
      overflow: visible;
      opacity: 1;
      width: 100%;
    }
  }
}

.search-field-group {
  display: flex;
  align-items: center;
  white-space: nowrap;
  transition: $transition;
  position: absolute;

  .ant-dropdown-trigger {
    display: flex;
    white-space: nowrap;
    align-items: center;
    height: 38px;
    color: $grey-darken;
    background-color: $grey-light;
    border-radius: 0 38px 38px 0;
    padding: 0 20px 0 12px;
    border-left: 1px solid darken($grey-light, 6%);
    cursor: pointer;
    transition: $transition;
    font-size: 14px;

    i {
      margin-right: 5px;
    }

    &:hover {
      background-color: darken($grey-light, 6%);
    }
  }

  .ant-input-affix-wrapper {
    height: 38px;
    outline: none;
    border: none;
    padding: 0 10px 0 30px !important;
    border-radius: 38px 0 0 38px !important;
    width: 100%;
    border: 0 !important;
    box-shadow: none !important;
    z-index: 1;

    .ant-input-suffix {
      display: none !important;
    }

    input {
      font-family: $fontFamily;
      font-size: 16px;
      line-height: 38px;
      color: $textColor;

      @include placeholder {
        font-family: $fontFamily;
        font-size: 16px;
        line-height: 38px;
        color: $grey;
        font-weight: 300;
        opacity: 1;
      }
    }
  }

  .ant-input-group {
    display: flex;
  }

  .ant-input-group-addon {
    left: 0 !important;
    border-radius: 0 !important;
    background-color: transparent;
    display: flex;
    width: 38px;

    .ant-btn {
      font-size: 30px;
      background-color: $grey-light;
      padding: 0;
      min-height: auto;
      width: 38px;
      height: 38px;
      border-radius: 0 !important;
      color: $grey-darken !important;
      transition: $transition !important;

      &:hover {
        opacity: 1;
        background-color: darken($grey-light, 6%);
      }

      .anticon {
        left: 11px;

        svg {
          font-size: 18px;
        }
      }
    }
  }
}

.simple-dropdown-overlay,
.general-search-overlay {
  background-color: $white;
  box-shadow: $shadow-dropdown;

  ul {
    list-style: none;
    padding: 8px 0;
    margin: 0;

    li {
      padding: 7px 20px;
      cursor: pointer;
      color: $textColor;
      transition: $transition;
      user-select: none;

      &:hover {
        background-color: $grey-light;
      }
    }
  }
}
