.matrix-table-container {
  position: relative;

  &.loading {

    .preloader {
      position: absolute;
      left: 50%;
      top: 50px;
      transform: translateX(-50%);
    }

    .matrix-table {
      opacity: .6;
    }
  }
}

.matrix-table {
  position: relative;
  width: 100%;
  overflow: auto;
  @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));

  table {
    background-color: transparent;
    color: $textColor;
    border: 1px solid $border-table;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-top: 0;
    border-left: 0;

    .vertical-text {
      writing-mode: vertical-rl;
    }

    thead tr,
    tbody tr {
      th,
      td {
        border: 1px solid $border-table;
        padding: 12px;
        font-size: 14px;
        font-weight: 400;
      }

      th {
        color: $textColor;
        font-size: 14px;
        white-space: nowrap;
        text-align: left;
        background-color: $white;
        border: 0;
        font-weight: 500;

        &.title {
          font-size: 14px;
          text-align: center;
        }
      }
    }

    tbody tr {
      &:first-child {

        td {
          border-top: 0;
        }
      }

      td {
        text-align: center;

        &.hover {
          background-color: $grey-light;
        }

        &:first-of-type {
          border-left: 0;
        }
      }
    }

    tbody tr th {
      min-width: 235px;
      max-width: 235px;
      width: 235px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: default;
      position: sticky;
      left: 0;
      background-color: #ffffff;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        border-left: 1px solid $border-table;
        right: 0;
        height: 100%;
        bottom: 0;
      }
    }

    thead tr th {
      cursor: default;
      white-space: nowrap;
      vertical-align: middle;
      text-align: right;
      background-color: $white;
      position: sticky;
      top: 0;
      z-index: 1;

      &:after {
        border-bottom: 1px solid $border-table;
        bottom: 0;
        box-shadow: 1px 2px 10px #eeeeee;
        content: '';
        left: 0;
        position: absolute;
        width: 100%;
      }

      &:first-child {
        left: 0;
        z-index: 3;
        min-width: 235px;
        max-width: 235px;
        width: 235px;
        display: table-cell;
        vertical-align: bottom;
        padding-bottom: 14px;
        text-align: center;
        position: sticky;
        top: 0;

        &:before {
          content: '';
          position: absolute;
          border-left: 1px solid $border-table;
          right: 0;
          height: 100%;
          bottom: 0;
        }

        &:after {
          border-bottom: 1px solid $border-table;
          bottom: 0;
          box-shadow: 1px 2px 10px #eeeeee;
          content: '';
          left: 0;
          position: absolute;
          width: 100%;
        }
      }
    }

    .matrix-cell {
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;

      .matrix-cell-content {
        width: 25px;
        height: 22px;

        .ti-check.text-primary {
          font-size: 22px;
        }
      }
    }
  }
}

.matrix-cell-checkbox {
  width: 25px;
  height: 25px;
  border: 1px solid $inputBorderColor;
  border-radius: $inputBorderRadius;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: $transition;
  background-color: $white;

  &:hover {
    border-color: $primary;
  }

  i {
    opacity: 0;
    transition: $transition;
  }

  &.checked {
    border-color: $primary;

    i {
      color: $primary;
      opacity: 1;
    }
  }

  &.disabled {
    border-color: $inputBorderColor;
    background-color: $grey-light;
    opacity: .7;

    &:hover {
      cursor: not-allowed;
      border-color: $inputBorderColor;
    }
  }
}
