.file-box {
  display: flex;
  align-items: flex-start;
  padding-top: 2px;

  &.clickable {
    cursor: pointer;
  }

  .file-box-name-ext {
    display: flex;
    align-items: flex-start;
    flex: 1;
    max-width: 100%;
  }

  .file-extension {
    margin-top: -1px;
  }

  &.with-delete,
  &.with-download {
    .file-box-name-ext {
      max-width: calc(100% - 20px);
    }
  }

  &.with-download.with-delete {
    .file-box-name-ext {
      max-width: calc(100% - 40px);
    }
  }

  .file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .actions {
    display: flex;
    margin-left: auto;
    margin-top: 5px;

    .action-item {
      display: inline-flex;
      margin-left: 5px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
