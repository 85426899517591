.ant-result-success .ant-result-icon > .anticon {
  color: $primary;
}

.ant-result-title,
.ant-result-subtitle {
  font-family: $fontFamily;
  font-weight: 300;
  font-size: 16px;
  color: $textColor;
}

.ant-result-title {
  font-size: 22px;
  font-weight: 400;
}

.result-success-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 100%;
}
