.active-switch-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    cursor: not-allowed!important;
    opacity: 0.3;
  }
}
