.languages-trigger {
  img {
    width: 25px;
    height: auto;
  }
}

.dropdown-languages-overlay {
  width: 160px;
  top: 68px !important;

  img {
    max-width: 100%;
    max-height: 18px;
    padding-bottom: 3px;
    margin-right: 10px;
  }

  span {
    font-size: 14px;
    font-weight: 300;
  }
}
