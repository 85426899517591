.download-btn {
  width: 30px;
  height: 30px;
  min-height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin: 0;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
