.not-found-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1170px;

  @media #{$media-down-x} {
    flex-direction: column;

    svg {
      width: 100%;
      max-width: 300px;
      height: auto;
      margin-bottom: 50px;
    }

    .display-large {
      font-size: 34px;
    }

    .headline-large {
      font-size: 24px;
    }
  }

  .text {
    max-width: 450px;
  }
}
