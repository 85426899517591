.ant-radio {
  color: $textColor;
}



.ant-radio-wrapper {
  font-weight: 300;
}

.ant-form label.ant-radio-wrapper {
  font-size: 16px;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-checked .ant-radio-inner {
  border-color: $primary;
}

.ant-radio-checked::after {
  border: 1px solid $primary;
}

.ant-radio-inner::after {
  background-color: $primary;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none;
}
