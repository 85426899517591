.qam-dashboard-calendar {
  .calendar-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .calendar-title {
    margin-right: auto;
  }

  .calendar-date {
    font-size: 13px;
    margin-right: 5px;
  }

  .buttons-group {
    margin-bottom: 0;

    .ant-btn {
      min-height: auto;
      padding: 10.5px 10px;

      i {
        font-size: 12px;
      }
    }
  }

  .date-buttons {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .right {
    display: flex;
    align-items: center;
  }
}

.dashboard-columns-container {
  flex: 1;
  margin-bottom: 15px;
}
