.notify {
  position: relative;
  width: 6px;
  height: 6px;

  &:before {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 30px;
    background-color: #f62d51;
    position: absolute;
    right: 6px;
    top: -10px;
  }

  &:after {
    content: "";
    position: absolute;
    top: -20px;
    right: -4px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 5px solid #f62d51;
    border-radius: 70px;
    animation: heartbit 1s ease-out;
    animation-iteration-count: infinite;
  }
}

@keyframes heartbit {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
