.full-calendar-container {
  position: relative;

  .preloader {
    position: absolute;
    right: 50%;
    top: 150px;
    transform: translateX(-50%);
    z-index: 1;
  }

  .fc {
    transition: $transition;
  }

  &.loading {
    .fc {
      opacity: 0.6;
    }
  }
}

.fc .calendar-year-view {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    border-spacing: 0;
    font-size: 12px;
  }

  .left-part {
    width: 40px;
    min-width: 40px;
    z-index: 2;

    th {
      position: relative;
      border-right: none;
      background-color: $grey-light;
      height: 34px;
      border-right: 1px solid $grey-light2;
      border-color: $grey-light2;
    }

    td {
      text-align: center;
      vertical-align: middle;
      font-size: 12px;
      font-weight: 400;
      height: 70px;
      border-color: $grey-light2;
    }
  }

  .right-part {
    width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    overflow: auto;
    @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));

    table {
      min-width: 1490px;
    }

    th {
      color: #2e3844;
      background-color: $grey-light;
      border-color: $grey-light2;
      text-align: center;
      vertical-align: middle;
      font-size: 12px;
      font-weight: 400;
      height: 34px;

      &:first-child {
        border-left: 0;
      }
    }

    td {
      border-color: $grey-light2;

      &:first-child {
        border-left: 0;
      }
    }
  }

  .year-table-cell {
    height: 69px;
    min-width: 48px;
    width: 100%;
    overflow: hidden;
  }
}

.year-table-cell {
  &.invalid {
    cursor: not-allowed;
    background: #f2f7f8 repeating-linear-gradient(-45deg, #ffffff, #ffffff 5px, #f2f7f8 5px, #f2f7f8 10px);
  }

  &.week {
    background: #f2f7f8;
  }
}

.year-event {
  box-sizing: border-box;
  border-left: 4px solid;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  padding-left: 2px;
  color: $textColorDarken;
  display: block;
  margin: 1px;
  width: calc(100% - 2px);
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
  text-overflow: ellipsis;
  line-height: 1.5;

  &:hover {
    opacity: 0.8;
  }

  &.has-more-days {
    position: relative;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 0.1s ease-in-out;

    .number-of-days {
      font-size: 10px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #fff;
      box-sizing: border-box;
      padding-right: 4px;
      text-align: center;
      display: block;
      position: absolute;
      opacity: 0;
      left: 0;
      top: 100%;
      width: 100%;
      height: 100%;
      transition: all 0.1s ease-in-out;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
      color: transparent;

      .number-of-days {
        opacity: 1;
        top: 0;
      }
    }
  }
}

.show-more-events {
  font-size: 10px;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  line-height: 1;
  width: 100%;
  display: block;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popover-year-cell-events {
  max-width: 250px;

  .ant-popover-inner-content {
    padding: 0;
  }

  .year-popover-events {
    padding: 10px 15px 15px;
  }

  .title {
    padding: 5px 25px 5px 15px;
    background-color: $lightDarken;
    font-weight: 300;
    font-size: 16px;

    .ti-close {
      font-size: 14px;
      position: absolute;
      cursor: pointer;
      right: 8px;
      top: 10px;
    }
  }

  .year-event {
    margin: 0 0 5px 0;
    font-size: 14px;
    border: 0;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;

    &.course-event {
      border-left: 4px solid;
    }

    .d-none {
      display: block;
      width: 12px;
      min-width: 12px;
      height: 12px;
      margin-right: 4px;
    }

    &:hover {
      opacity: 1;
    }

    .year-event-title {
      width: calc(100% - 16px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.has-more-days {
      background-color: transparent;

      .number-of-days {
        display: none;
      }

      &:hover {
        color: $textColor;
        opacity: 1;
      }
    }
  }
}

.fc.fc-theme-standard .fc-scrollgrid,
.fc.fc-theme-standard td,
.fc.fc-theme-standard th {
  border-color: $grey-light2;
}

.fc.fc-theme-standard th {
  background: $grey-light;
}

.fc.fc-theme-standard .fc-col-header-cell-cushion {
  color: $textColor;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  cursor: default;
}

.fc.fc-theme-standard .fc-daygrid-day-number {
  user-select: none;
  pointer-events: none;
  font-size: 18px;
  font-weight: 300;
  color: $textColor;
}

.fc.fc-theme-standard .fc-event {
  color: $textColorDarken;
  display: block;
  max-width: 100%;

  &:hover {
    background-color: transparent;
    color: rgba($textColor, 0.8);
  }

  &.fc-event-selected:after,
  &.fc-event:focus:after {
    display: none;
  }

  &.fc-event-selected,
  &.fc-event:focus {
    box-shadow: none;
  }

  > span {
    overflow: hidden;
    display: flex;
    white-space: nowrap;
    width: 100%;
    align-items: center;
  }

  .square {
    margin-right: 2px;
    min-width: 12px;
    height: 12px;
  }

  .calendar-activity-event {
    width: calc(100% - 14px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fc.fc-theme-standard .fc-daygrid-day.fc-day-today {
  background-color: rgba($primary, 0.2);
}

.fc-daygrid-more-link {
  .show-more-events {
    font-size: 14px;
  }
}

.fc.fc-theme-standard .fc-scroller-liquid-absolute {
  @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));
}
.calendar-date-details{
  text-align: center;
  margin-bottom: 10px;
}
