$space: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60);
$pos: (top, bottom, left, right);

@each $s in $space {
  .m#{$s} {
    margin: #{$s}px;
  }
  .p#{$s} {
    padding: #{$s}px;
  }
  @each $p in $pos {
    .m#{str-slice($p, 0, 1)}-#{$s} {
      margin-#{$p}: #{$s}px;
    }
    .p#{str-slice($p, 0, 1)}-#{$s} {
      padding-#{$p}: #{$s}px;
    }
  }
}

.ml-a {
  margin-left: auto;
}

.mr-a {
  margin-right: auto;
}

.mt-a {
  margin-top: auto;
}

.mb-a {
  margin-bottom: auto;
}

.m-a {
  margin: auto;
}

.mr-1 {
  margin-right: 1px;
}

/*
Generated Result:
m10 { margin: 10px; }
mt-10 { margin-top: 10px; }
...
 */
