.catalogues-norms {
  position: relative;
  margin-top: 10px;
  transition: $transition;

  .outer-table-container {
    padding: 0;
  }

  .catalogues-norms-list {
    transition: $transition;
  }

  &.loading {

    .catalogues-norms-list {
      opacity: .5;
    }

    .preloader {
      position: absolute;
      left: 50%;
      top: 50px;
      transform: translateX(-50%);
    }
  }
}

.catalogues-norms-list {

  .norm-collapse-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .ant-collapse-header-text {
      width: calc(100% - 24px);
    }

    .ant-collapse-expand-icon {
      line-height: 1;
    }
  }
}

.draggable-section-container {

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 30px;
    position: relative;
  }

  .draggable-icon {
    position: absolute;
    left: 9px;
    top: 9px;
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging {
  font-size: 14px;
  font-weight: 300;
  z-index: 3;

  td {
    padding: 16px;
  }

  .ant-table-cell-fix-right {
    opacity: 0;
  }
}

.row-dragging .drag-visible {
  visibility: visible;
}

.norms-loading {
  position: relative;

  .catalogues-norms {
    opacity: 0.6;
  }

  > .preloader {
    top: 50px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}
