.single-page-view {
  display: flex;
  min-height: 100%;

  @media #{$media-down-lg} {
    flex-direction: column;
  }
}

.ant-tabs-tabpane {
  .outer-table-container {
    padding-top: 0;
  }

  .table-outer-filters {
    padding: 0;
  }
}

.single-page-sidebar {
  margin-right: 15px;
  width: 25%;

  @media #{$media-down-lg} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.single-page-content {
  width: calc(75% - 15px);
  position: relative;
  overflow: hidden;

  @media #{$media-down-lg} {
    width: 100%;
  }

  .single-page-back-btn {
    position: absolute;
    right: 15px;
    top: 7.5px;
    z-index: 100;

    @media #{$media-down-lg} {
      display: none;
    }
  }
}
