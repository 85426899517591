$navigationLinksColor: #212529;
$navigationSubmenuActiveLinksColor: #263238;
$navigationLinksFontSize: 16px;

.ant-menu.layout-sidebar-navigation {
  background: $white;
  margin-top: 3px;

  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: transparent;
  }

  .ant-menu-item {
    background-color: transparent;
    margin: 0 0 5px 0;
    height: 60px;
    line-height: normal;
    white-space: normal;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: $transition;
    padding: 0 30px 0 22px !important;
    font-size: $navigationLinksFontSize;
    font-weight: 300;
    user-select: none;

    &:hover {
      i,
      .ant-menu-title-content {
        color: $primary;
      }
    }

    .ant-menu-title-content {
      color: $navigationLinksColor;
      user-select: none;
      margin-left: 4px;
    }

    i {
      color: $navigationLinksColor;
      width: 27px;
      font-size: 16px;
    }

    &.ant-menu-item-selected {
      background-color: transparent;

      i,
      .ant-menu-title-content {
        color: $primary;
      }
    }
  }

  .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    transform: rotate(-135deg) translate(0, -50%) !important;
  }

  .ant-menu-submenu {
    color: $navigationLinksColor;

    .ant-menu-submenu-title {
      margin: 0 0 5px 0;
      font-weight: 300;
      font-size: $navigationLinksFontSize;
      padding: 0 30px 0 22px !important;
      height: 60px;
      line-height: normal;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;
      user-select: none;

      &:hover {
        i,
        .ant-menu-title-content {
          color: $primary;
        }
      }
    }

    .ant-menu-title-content {
      user-select: none;
      margin-left: 4px;
      color: $navigationLinksColor;
    }

    i {
      color: $navigationLinksColor;
      width: 27px;
      font-size: 16px;
    }

    .ant-menu-sub {
      background-color: transparent !important;

      .ant-menu-item-selected {
        .ant-menu-title-content {
          color: $navigationSubmenuActiveLinksColor;
          font-weight: 400;
        }
      }

      .ant-menu-item {
        height: 42px;
      }
    }
  }

  &.ant-menu-inline-collapsed {
    .ant-menu-submenu-arrow {
      display: none !important;
    }

    .ant-menu-submenu .ant-menu-submenu-title,
    .ant-menu-item {
      height: 45px;
      margin: 0 0 10px 0;
    }
  }

  .ant-menu-submenu-arrow {
    opacity: 1 !important;
    position: absolute;
    content: "";
    width: 0.4em !important;
    height: 0.4em;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #2e3844;
    right: 1em;
    transform: rotate(-45deg) translate(0, -50%);
    transform-origin: top;
    top: 50%;
    transition: all 0.3s ease-out;

    &:after,
    &:before {
      display: none;
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      i,
      .ant-menu-title-content {
        color: $primary;
      }
    }
  }
}

.ant-menu-submenu-popup {
  .ant-menu-sub {
    background-color: #e8eff0 !important;
    min-width: 200px !important;

    .ant-menu-item {
      padding: 10px 35px 10px 15px;
      margin: 0;
      height: auto;
      line-height: normal !important;

      &:hover {
        .ant-menu-title-content {
          color: $primary !important;
        }
      }

      &.ant-menu-item-selected {
        background-color: #e8eff0 !important;

        .ant-menu-title-content {
          font-weight: 400;
          color: $navigationSubmenuActiveLinksColor !important;
        }
      }
    }

    .ant-menu-title-content {
      font-size: $navigationLinksFontSize;
      color: $navigationLinksColor !important;
      font-weight: 300;
    }
  }
}

.scrollbar-track-vertical {
  position: absolute;
  width: 5px !important;
  left: 0;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
  opacity: 0.5;

  &.right {
    left: auto;
    right: 0;
  }
}
