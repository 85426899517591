.trg-reporting-content {
  position: relative;
  min-height: 100px;

  .trg-reporting-table {
    transition: $transition;
  }

  &.loading {

    .trg-reporting-table {
      opacity: .5;
    }
  }

  .preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.reporting-header {

  .filters-container {
    flex: 1;

    .ant-select {
      flex: 1;
      width: auto;
      min-width: 140px;
      max-width: 200px;
    }
  }

  @media (max-width: 1450px) {
    .filters-container {
      flex-wrap: wrap;

      .ant-select {
        width: 200px;
      }
    }
  }
}

.view-all-reporting {
  display: flex;
  align-items: center;

  > span {
    margin-top: 2px;
  }
}

.reporting-table.matrix-table {
   table thead tr th:first-child {
     min-width: 350px;
     width: 350px;
   }

  table tbody tr th {
    min-width: 350px;
    width: 350px;
    padding: 0;
    border-bottom: 1px solid $border-table;
  }

  table tbody tr {

    &:hover {

      td {
        background-color: $grey-light;
      }
    }

    td {
      text-align: center;
      min-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.red {background-color: #e57373 !important;}
      &.yellow {background-color: #ffbc34 !important;}
      &.green {background-color: #a5d6a7 !important;}
      &.lightblue {background-color: #add8e6 !important;}
    }
  }
}

.reporting-user-card {
  display: flex;

  .left {
    flex: 1;
    padding: 12px;
    width: calc(100% - 29px);
  }

  .right {
    width: 30px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid $border-table;

    > div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;

      &:first-child {
        border-bottom: 1px solid $border-table;
      }
    }
  }

  .show-more-list {

    .item {
      color: $text-muted;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 1px;
      margin-bottom: 1px;
    }

    .show-more-list-trigger {
      margin-top: -2px;
      font-size: 10px;
    }
  }
}
