.link,
a {
  font-family: $fontFamily;
  line-height: 20px;
  font-weight: 300;
  color: $linkColor;
  transition: $transition;
  cursor: pointer;

  &:active {
    color: $linkColor;
  }

  &:hover {
    color: rgba($linkColor, 0.8);
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    color: rgba($linkColor, 0.3);
  }

  &.text-primary {
    color: $primary;

    &:hover {
      color: rgba($primary, 0.8);
    }

    &.disabled,
    &[disabled] {
      cursor: not-allowed;
      color: rgba($primary, 0.3);
    }
  }

  &.text-secondary {
    color: $secondary;

    &:hover {
      color: rgba($secondary, 0.8);
    }

    &.disabled,
    &[disabled] {
      cursor: not-allowed;
      color: rgba($secondary, 0.3);
    }
  }

  &.text-muted {
    color: $text-muted;

    &:hover {
      color: rgba($text-muted, 0.8);
    }

    &.disabled,
    &[disabled] {
      cursor: not-allowed;
      color: rgba($text-muted, 0.3);
    }
  }

  &.text-danger {
    color: rgba($danger, 0.8);

    &:hover {
      color: $danger;
    }

    &[disabled] {
      color: rgba($danger, 0.3);
    }
  }

  &.black {
    color: $textColorDarken;

    &:hover {
      color: rgba($textColorDarken, 0.8);
    }
  }

  &.ant-btn {
    padding: 5px 30px !important;
    line-height: 1;
  }
}

.icon-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 33px;
  min-height: 33px;
  transition: $transition;
  padding: 7px;
  background-color: transparent;
  font-size: 17px;
  color: $primary;

  &:active,
  &:focus {
    color: $primary;
    background-color: transparent;
  }

  &:hover {
    background-color: transparent;
    color: rgba($primary, 0.8);
  }

  &.white {
    color: rgba($white, 0.8);

    &:active,
    &:focus {
      color: rgba($white, 0.8);
      background-color: transparent;
    }

    &:hover {
      background-color: transparent;
      color: $white;
    }
  }
}

.link-text-icon {
  display: flex;
  align-items: center;

  i {
    margin-left: 5px;
    font-size: 12px;
  }
}

.link-icon-text {
  display: flex;
  align-items: center;

  i {
    margin-right: 5px;
    font-size: 12px;
  }
}
