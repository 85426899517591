.filters-container {

  .ant-select {
   width: 200px;
  }
}

.training-program-container {

  .training-program-list-inner {
    transition: $transition;
  }

  &.loading {

    .training-program-list-inner {
      opacity: .5;
    }
  }

  &.active-filter {

    .draggable-icon {
      display: none;
    }
  }
}

.training-program-list {
  position: relative;

  > .preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.training-program-item {
  display: flex;
  margin: -1px 0px;
  min-height: 172px;
  border: 1px solid #dcdcdc;
  background-color: $white;

  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    min-width: 150px;
    padding: 10px;
    border-right: 1px solid $inputBorderColor;
    position: relative;

    .function-name {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2;
      max-height: 150px;
      overflow: hidden;
      text-align: center;
      transform: rotateZ(180deg);
      writing-mode: vertical-rl;
    }

    .draggable-icon {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .right {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .training-box {
    box-shadow: 0 0 4px rgba(55, 71, 79, 0.25);
    display: flex;
    font-size: 14px;
    margin: 5px;
    overflow: hidden;
    padding: 10px 5px;
    position: relative;
    text-align: center;
    width: 190px;
    min-width: 190px;
    justify-content: center;
    align-items: center;
    min-height: 80px;

    .required-circle {
      cursor: default;
      position: absolute;
      top: 3px;
      left: 3px;
      overflow: hidden;
      line-height: 13px;
      font-size: 10px;
      font-weight: 500;
      width: 14px;
      min-width: 14px;
      height: 14px;
      border-radius: 14px;
      border: 1px solid $darken;
      background-color: $white;
    }

    .actions {
      position: absolute;
      top: 0;
      right: 0;
      overflow: hidden;

      a {
        line-height: 12px;
        font-size: 11px;
        margin-right: 3px;
        margin-top: 2px;
      }
    }

    .action-items {
      display: flex;
      background-color: $white;
      overflow: hidden;
      height: 16px;
      padding-left: 3px;
      transform: translateX(100%);
      transition: $transition;
    }

    &:hover {

      .action-items {
        transform: translateX(0);
      }
    }

    &.required {

      .training-name {
        max-width: calc(100% - 30px);
      }
    }

    .training-name {
      font-weight: 300;
      color: $white;
      text-shadow:
        -1px -1px 0 $textColor,
        0   -1px 0 $textColor,
        1px -1px 0 $textColor,
        1px  0   0 $textColor,
        1px  1px 0 $textColor,
        0    1px 0 $textColor,
        -1px  1px 0 $textColor,
        -1px  0   0 $textColor;


      .name {
        font-size: 14px;
      }

      .code {
        font-size: 12px;
      }
    }
  }
}

.training-program-list-inner {
  padding-top: 1px;
}

.training-program-page {
  padding-top: 0;

  .training-program-header {
    position: sticky;
    top: 67px;
    z-index: 99;
    padding-top: 15px;
    background: $white;
  }
}
