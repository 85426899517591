.tox.tox-tinymce {
  border: 1px solid $inputBorderColor;
  border-radius: $inputBorderRadius;
  min-height: 130px !important;

  &:not(.tox-tinymce-inline) .tox-editor-header {
    box-shadow: none;
    padding: 0;
    border-bottom: 1px solid $inputBorderColor;
  }

  .tox-mbtn {
    height: 23px;
  }

  .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active) {
    background: $lightDarken;
  }

  .tox-mbtn:focus:not(:disabled),
  .tox-mbtn--active {
    background: $lightDarken;
  }

  .tox-tbtn {
    height: 24px;
    width: 30px;
  }

  .tox-tbtn--bespoke {
    width: auto;
  }

  .tox-toolbar__group {
    padding-right: 5px;
  }

  .tox-tbtn:hover {
    background: $lightDarken;
  }

  .tox-tbtn:focus,
  .tox-tbtn:active,
  .tox-tbtn--enabled,
  .tox-tbtn--enabled:hover {
    background: $lightDarken;
  }

  .tox-tbtn--disabled,
  .tox-tbtn--disabled:hover,
  .tox-tbtn:disabled,
  .tox-tbtn:disabled:hover {
    background-color: #fff;
  }
}

.tox.tox-tinymce-aux {
  .tox-collection--list .tox-collection__item--active {
    background-color: $lightDarken;
  }
}
