.custom-bordered-table {
  color: $textColor;
  border: 1px solid #e9ecef;
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;

  .head td {
    font-weight: 400;
  }

  > thead > tr,
  > tbody > tr {
    > th,
    > td {
      min-width: 95px;
      border: 1px solid $border-table;
      padding: 12px;
      font-size: 14px;
    }
  }
}

.color-square {
  display: inline-flex;
  width: 12px;
  height: 12px;
}

.responsive-table {
  display: block;
  width: 100%;
  overflow-x: auto;
  @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));

  > table {
    color: $textColor;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    font-size: 14px;

    td,
    th {
      padding: 12px;
    }

    th {
      font-weight: 500;
      text-align: left;
    }
  }
}
