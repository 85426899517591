.tm-content {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10px;
  padding-bottom: 40px;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: $grey-light2;
  }
}

.tm-line-dot,
.tm-line-start {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -12px;
  font-size: 26px;
  color: $grey-light2;
}

.tm-line-dot {
  background-color: $grey-light2;
  width: 10px;
  height: 10px;
  top: auto;
  bottom: 0;
  border-radius: 10px;
}

.tm-title {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;

  > div {
    width: calc(50% - 50px);
    text-align: center;
  }
}

.tm-items {
  display: flex;
  flex-direction: column;
}

.tm-item {
  margin-bottom: 20px;
  width: calc(50% - 40px);
  position: relative;

  &.right {
    margin-left: auto;

    .tm-avatar {
      left: -60px;
    }

    .tm-item-content {
      &:after {
        right: auto;
        left: -9px;
        border-right: 10px solid #fff;
        border-left: none;
        z-index: 2;
      }

      &:before {
        right: auto;
        left: -10px;
        border-right: 10px solid $grey-light2;
        border-left: none;
        z-index: 1;
      }
    }
  }

  &.left {

    .tm-avatar {
      right: -60px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.selected {

    .tm-item-content {
      border: 1px solid $primary;
      background-color: $lightSuccess;

      &:before {
        border-right-color: $primary;
        border-left-color: $primary;
      }

      &:after {
        border-right-color: $lightSuccess;
        border-left-color: $lightSuccess;
      }
    }
  }

  .tm-avatar {
    position: absolute;
    top: -5px;
    z-index: 1;
  }
}

.tm-item-content {
  border: 1px solid $grey-light2;
  padding: 20px;

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 8px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  &:after {
    right: -9px;
    border-left: 10px solid #fff;
    z-index: 2;
  }

  &:before {
    right: -10px;
    border-left: 10px solid $grey-light2;
    z-index: 1;
  }
}

.tm-item-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .title {
    flex: 1;
    margin-right: auto;
  }

  .actions {
    margin-top: -10px;
    margin-right: -10px;

    a {
      font-size: 14px;
      margin-left: 5px;
    }
  }
}

.tm-description {

  p:last-of-type {
    margin-bottom: 0;
  }
}

.tm-item-author {
  font-size: 13px;
  font-weight: 300;
  color: $text-muted;
  text-align: right;
  margin-top: 2px;
}
