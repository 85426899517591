@function get-list($start, $end, $step: 1) {
  $array: "";
  $i: $start;

  @while $i + $step <= $end {
    $array: append($array, $i + $step, comma);
    $i: $i + $step;
  }
  $array: set-nth($array, 1, $start);

  @return $array;
}

@mixin width() {

  $font-size-list: get-list(0, 100);

  @each $value in $font-size-list {
    .w-#{$value} {
      width: percentage($value/100);
    }
  }
}

@mixin widthPX() {

  $font-size-list: get-list(0, 1000);

  @each $value in $font-size-list {
    .w-#{$value}-px {
      width: #{$value}px;
    }
  }
}

.min-w-150 {
  min-width: 150px;
}

.min-w-130 {
  min-width: 130px;
}

.min-w-110 {
  min-width: 110px;
}

.min-w-120 {
  min-width: 120px;
}

.min-w-450 {
  min-width: 450px;
}

.min-w-250 {
  min-width: 250px;
}

.w-200 {
  width: 100%;
  max-width: 200px;
}

.min-h-100 {
  min-height: 100%;
}

.w-100 {
  width: 100%;
}

@include width()
@include widthPX()

