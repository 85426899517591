.step-1 {
  .attachment-input {
    margin-bottom: 0;
  }
}

.step-2 {
  width: 100%;

  &-box {
    width: 50%;
    margin-right: 20px;

    &-btn {
      //padding: 8px;
      height: 100%;
      width: 50px;
      border: 1px solid #87ca97;
      border-radius: 4px;
      margin-left: 10px;

      &-none {
        padding: 7px;
        border: 1px solid #87ca97;
        border-radius: 2px;
      }
    }
  }

  &-search {
    .ant-input-group-addon {
      display: none !important;
    }
  }

  &-options {
    border: 1px solid #e3e8ea;
    padding: 10px;
    border-radius: 4px;
    height: 180px;
    overflow: auto;
    @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));
  }
}

.question-inputs-container {

  width: 70%;

  .ant-form-item {
    margin-bottom: 0;
  }

  .publication-question-input {
    width: 100%
  }

  .answers-wrapper {
    width: 100%;

    .answer-container {
      width: 100%;
      margin-bottom: 15px;

      .publication-answer {
        width: 50%;
      }
    }
  }

  .publication-form-delete {
    color: rgba(0, 0, 0, 0.25);
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

