.ant-checkbox-wrapper {
  font-family: $fontFamily;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: $grey;

  &:hover {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: $primary;
      }
    }

    .ant-checkbox.ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: $primary;
      }
    }
  }

  .ant-checkbox-checked + span {
    transition: $transition;
  }
}

.ant-checkbox {
  & + span {
    color: $textColor;
    font-size: 16px;
  }

  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border: 1px solid $inputBorderColor;
    border-radius: 4px;
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: $primary;
    }
  }

  input {
    &:focus {
      & + .ant-checkbox-inner {
        border-color: $inputBorderColor;
      }
    }
  }

  &.ant-checkbox-checked {
    .ant-checkbox-inner::after {
      border-width: 1px;
      left: 22.5%;
    }

    input {
      &:focus {
        & + .ant-checkbox-inner {
          border-color: $primary;
        }
      }
    }

    &:after {
      border: 0;
    }

    .ant-checkbox-inner {
      background-color: $white;
      border: 1px solid $primary;

      &:after {
        border-color: $primary;
      }
    }
  }

  &.ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      border: 1px solid $primary !important;

      &:after {
        background-color: $primary;
      }
    }
  }
}

.ant-checkbox-group {

  > .ant-checkbox-wrapper {
    margin-right: 10px;
    margin-left: 0!important;
  }
}
