.preloader {
  .ant-spin-dot {
    width: auto;
    height: auto;
  }

  &.center-on-body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .ant-spin {
    color: $primary;
    line-height: 1;
  }
}
