.d-none {
  display: none;
}

.table-vertical-baseline  {
  tbody,
  .ant-table-tbody {
    td {
      vertical-align: baseline;
    }
  }
}

.table-vertical-top {

  td,
  th {
    vertical-align: top;
  }
}

.text-align-center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.opaque-wrapper {
  opacity: 0.5;
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  height: 100%;
}
