.participant-card {
  position: relative;
  display: flex;
  padding: 15px 15px 12px 15px;
  border-radius: $inputBorderRadius;
  font-weight: 400;
  background-color: $grey-light;
  transition: $transition;
  word-break: break-word;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $grey-light2;
  }

  .name {
    margin-bottom: 3px;
  }

  .left {
    width: 40px;
  }

  .right {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;

    a {
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 2px;
    }
  }
}

.participant-status-bar {
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  font-size: 12px;

  i {
    margin-top: 3px;
    margin-right: 3px;
    padding: 2px;

    &.ti-close {
      cursor: pointer;
    }
  }
}
