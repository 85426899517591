.evidences-container {

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 14px;
    font-weight: 300;

    li {
      padding: 4px 5px 4px 8px;
      background-color: rgba($grey, .1);
      border-radius: $inputBorderRadius;
      transition: $transition;
      margin-bottom: 4px;

      &:hover {
        background-color: rgba($grey, .2);
      }

      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
}
