// Container
.flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-around {
  justify-content: space-around;
}

.flex-items-start {
  align-items: flex-start;
}

.flex-items-end {
  align-items: flex-end;
}

.flex-items-center {
  align-items: center;
}

.flex-items-baseline {
  align-items: baseline;
}

.flex-items-stretch {
  align-items: stretch;
}

.flex-content-start {
  align-content: flex-start;
}

.flex-content-end {
  align-content: flex-end;
}

.flex-content-center {
  align-content: center;
}

.flex-content-between {
  align-content: space-between;
}

.flex-content-around {
  align-content: space-around;
}

.flex-content-stretch {
  align-content: stretch;
}

// Item
.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-self-auto {
  align-self: auto;
}

.flex-self-start {
  align-self: flex-start;
}

.flex-self-end {
  align-self: flex-end;
}

.flex-self-center {
  align-self: center;
}

.flex-self-baseline {
  align-self: baseline;
}

.flex-self-stretch {
  align-self: stretch;
}

.flex-order-1 {
  order: 1;
}

.flex-order-2 {
  order: 2;
}

.flex-order-none {
  order: inherit;
}

.flex-inline {
  display: inline-flex;
}
