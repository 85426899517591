@function get-list($start, $end, $step: 1) {
  $array: "";
  $i: $start;

  @while $i + $step <= $end {
    $array: append($array, $i + $step, comma);
    $i: $i + $step;
  }
  $array: set-nth($array, 1, $start);

  @return $array;
}

//add all font sizes
@mixin font-size() {

  $font-size-list: get-list(8, 150);

  @each $value in $font-size-list {
    .fs-#{$value} {
      font-size: #{$value}px;
    }
  }
}

// add all font weights
@mixin font-weight() {
  $font-weight-list: get-list(100, 1000, 100);

  @each $value in $font-weight-list {
    .font-weight-#{$value} {
      font-weight: $value;
    }
  }
}

h3,
.h3 {
  color: $textColor;
  line-height: 32px;
  font-size: 24px;
  font-weight: 400;
}

h4,
.h4 {
  color: $textColor;
  line-height: 22px;
  font-size: 20px;
  font-weight: 400;
}

.h5,
h5 {
  color: $textColor;
  line-height: 18px;
  font-size: 18px;
  font-weight: 400;
}

h6,
.h6 {
  color: $textColor;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
}

.nowrap {
  white-space: nowrap;
}

.word-ellipsis {
  max-width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-muted {
  color: $text-muted;
}

.text-darken {
  color: $textColor;
}

.text-danger {
  color: $danger;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-inline-small {
  font-size: 12px;
  display: block;
  margin: 1px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $grey-darken;
  white-space: nowrap;
  font-weight: 300;
}

.italic {
  font-style: italic;
}

.line-height-1 {
  line-height: 1;
}

.line-height-12 {
  line-height: 1.2;
}

.line-height-15 {
  line-height: 1.5;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.text-overflow-multiple {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow-wrap: normal;
}

.text-left-margin {
  margin-left: 5px;
}

// keeps same color even when disabled

.ant-input[disabled],
.ant-picker-input>input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-number input[disabled],
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: initial !important;
}

@include font-size()
@include font-weight()
