.qualifications-table {

  .table-outer-filters {
    position: relative;
  }

  .table-left-actions {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 10px;
  }

  .table-outer-filters .table-left-actions .table-external-filters {
    width: 100%;
    display: flex;
    margin-bottom: 0;
    margin-right: 0;
    flex: 1;
  }

  .table-right-actions {
    position: absolute;
    right: 15px;
    top: 0;

    @media (max-width: 890px) {
      position: static;
      flex-wrap: wrap;
    }
  }

  .qualification-external-filters {
    flex-wrap: wrap;
    flex: 1;
  }
}

.show-more-list.functions {

  .item {
    font-size: 12px;
    padding-left: 0;
    margin-bottom: 0;

    &:hover {
      background-color: transparent;
    }
  }

  .show-more-list-trigger {
    font-size: 10px;
  }
}

.export-qualification {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  a {
    font-size: 22px;
    margin-right: 5px;

    svg {
      height: 22px;
    }
  }
}

.date-in-past,
.training-not-done-yet {
  color: $danger;
}

.planned-circle {
  margin-left: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  font-size: 13px;
  border: 1px solid $grey-darken;
  line-height: 1;
  font-weight: 400;
}
