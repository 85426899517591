.calendar-header-nav.table-outer-filters {
  padding: 0;

  .activities-views-links {
    margin-bottom: 10px;
  }
}

.buttons-group {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;

  .ant-btn {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    border: 1px solid $inputBorderColor;
    padding: 11px 10px;
    color: #2c2b2e;
    line-height: 1;
    border-radius: 0;
    background-color: #fff;
    font-size: 16px;
    margin-left: -1px;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      margin-left: 0;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      background-color: $grey-light;
      opacity: 1;
    }

    &.active {
      background-color: $grey-light2;
    }

    i {
      margin: -2px 0 0 0;
      font-size: 17px;

      ~ span {
        margin-left: 5px;
      }
    }
  }
}

.calendar-filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;

  .ant-input-search {
    width: 200px;
    margin-bottom: 10px;
    height: 40px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: -1px;

    .ant-input-affix-wrapper {
      border-top-left-radius: $inputBorderRadius!important;
      border-bottom-left-radius: $inputBorderRadius!important;
    }

    .ant-input-group-addon {
      display: none!important;
    }
  }

  .ant-input-number {
    width: 100px;
    border-radius: 0;
    margin-bottom: 10px;

    &:hover {
      z-index: 1;
    }

    &.rounded {
      border-bottom-right-radius: $inputBorderRadius;
      border-top-right-radius: $inputBorderRadius;
    }
  }

  .ant-select {
    width: auto;
    min-width: 200px;
    max-width: 300px;
    margin-left: -1px;
    margin-bottom: 10px;

    &:hover {
      z-index: 1;
    }

    &:last-child {
      &:not(.ant-select-customize-input) .ant-select-selector {
        border-bottom-right-radius: $inputBorderRadius;
        border-top-right-radius: $inputBorderRadius;
      }
    }

    &:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 0;
    }
  }
}

.calendar-legend {
  display: flex;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 5px;

  .title {
    margin-right: 10px;
    margin-top: 5px;
  }
}

.legend-items {
  display: flex;
  flex-wrap: wrap;

  > div {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-top: 5px;

    .square {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }
}
