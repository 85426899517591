.participants-filters {
  display: flex;
  margin-bottom: 15px;

  .left {
    margin-right: 15px;
    display: flex;
    flex: 1;

    button {
      width: 40px;
      height: 40px;
    }
  }

  .right {
    width: calc(50% - 58px);
  }
}

.participants-list-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.participants-list + .participants-list {
  margin-left: 15px;
  width: calc(50% - 15px);
  min-width: calc(50% - 15px);
}

.participants-list {
  font-weight: 300;
  padding: 10px;
  height: 155px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid $inputBorderColor;
  border-radius: $inputBorderRadius;
  @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));
}

.participant-checkbox-item {

  &.hidden {
    display: none;
  }
}

.planning-view-page {

  .single-page-content {
    padding: 0;
  }
}

.single-page-header-title {
  padding: 15px;
}
