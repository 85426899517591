.navigation-collapsed .dropdown-new-item-overlay {
  @media #{$media-down-md} {
    width: 100%;
  }
}

.dropdown-new-item-overlay {
  background-color: $white;
  width: 100%;
  top: 68px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0 3px 12px 0;
  padding: 30px 30px 0 30px;

  .inner {
    display: flex;
    flex-wrap: wrap;
  }

  .new-items-group {
    flex: 1;
    margin-bottom: 30px;

    @media #{$media-down-lg} {
      min-width: 150px;
    }
  }

  .h4 i {
    font-size: 12px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    a {
      color: $grey;
      display: flex;
      padding: 10px;
      font-size: 14px;
      align-items: flex-start;
      width: calc(100% - 10px);
      transition: $transition;

      &:hover {
        background-color: $grey-light;
      }

      i {
        margin-right: 5px;
        margin-top: 3px;
      }
    }
  }
}
