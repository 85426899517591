.global-preloader {
  opacity: 1;
  display: flex;
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  z-index: 91919;
  transition: opacity 0.3s ease-out;

  &.exiting {
    opacity: 0;
  }

  .preloader {
    display: flex;
    margin: auto;
  }

  &.hide-preloader {
    opacity: 0;
  }

  .ant-spin-dot-item {
    background-color: $primary;
  }
}
