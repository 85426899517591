.custom-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: $white;
  font-size: 17px;

  &.danger {
    background: $danger;
  }

  &.circle {
    border-radius: 50%;
  }

  &.ghost {
    padding: 0;
    border: 1px solid $secondary;
    color: $secondary;
    width: 17px;
    height: 17px;
    font-size: 10px;

    &.active {
      color: $primary;
      border-color: $primary;
    }
  }
}
