.stage-column {
  display: inline-flex;
  align-items: center;
  line-height: 1;

  .square {
    width: 10px;
    min-width: 10px;
    height: 10px;
    margin-right: 4px;
    margin-top: -2px;
  }
}

.options-column {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      font-size: 13px;
      font-weight: 500;
      align-items: center;
      margin-bottom: 2px;

      &:hover {
        background-color: #fff;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .option-name {
    flex: 1;
    display: flex;

    .square {
      margin-right: 4px;
      margin-top: 4px;
      width: 10px;
      min-width: 10px;
      height: 10px;
    }
  }

  .option-actions {
    a {
      margin-right: 7px;
    }
  }

  .option-ca-required {
    min-width: 85px;
    width: 85px;
    text-align: center;
  }
}

.table-root-causes {
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    > * {
      margin: 0 5px;
    }
  }
}
