.custom-timeline-container {
  position: relative;
  overflow: auto;
  padding-bottom: 10px;
  min-height: 83px;
  @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));

  .preloader {
    position: absolute;
    top: 30%;
    right: 50%;
    transform: translateX(-50%);
  }
}

.custom-timeline {
  position: relative;
  display: flex;
  align-items: flex-start;
  font-weight: 300;
  transition: $transition;

  &.loading {
    opacity: 0.4;
  }

  .timeline-item-container {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;

    &:after {
      position: absolute;
      background-color: $grey-light3;
      display: block;
      content: "";
      height: 2px;
      left: 0;
      top: 56px;
      right: 0;
    }

    &:first-child {
      &:before {
        content: "";
        display: block;
        position: absolute;
        background-color: $grey-light3;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        left: 0;
        top: 52px;
      }
    }

    &:last-child {
      &:after {
        right: 7px;
      }

      &:before {
        color: $grey-light3;
        font-family: "themify";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        position: absolute;
        content: "\e649";
        top: 45px;
        font-size: 25px;
        right: 0;
      }
    }
  }

  .timeline-item {
    width: auto;
    min-width: auto;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.is-clickable {
      cursor: pointer;
    }
  }

  .title {
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 8px;
    width: 100%;
  }

  .no {
    position: relative;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 2px solid $grey;
    background-color: $white;
    z-index: 1;
  }

  .content {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    text-align: center;
    margin-top: 15px;
    max-width: 100%;
    border-radius: 2px;
    border: 1px solid $grey-light3;

    &:after {
      display: block;
      content: "";
      position: absolute;
      top: -9px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }

    &:before {
      display: block;
      content: "";
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $grey-light3;
    }

    .square {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }
}

.rsm-timeline {
  .timeline-item {
    width: 200px;
  }
}
