$filtersWidth: 300px;
$filterBackground: $lightInfo;

.activity-criteria-norms {
  position: relative;
}

.criteria-filters {
  width: auto!important;
  position: absolute;
  right: -16px;
  top: -16px;
  z-index: 3;

  .ant-affix {
    width: auto!important;
  }

  &.active-filters:not(.filter-panel-visible) {

    .criteria-filters-trigger {
      background-color: $lightSuccess;
    }
  }
}

.criteria-filters-container-outer {
  position: relative;
  display: flex;
}

.criteria-filters-trigger {
  cursor: pointer;
  padding: 0 15px;
  height: 25px;
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: 0 100%;
  background: $filterBackground;
  left: 0;
  top: 49px;
}

.criteria-filters-content {
  width: $filtersWidth;
  background-color: $filterBackground;
  padding: 10px;
}

.criteria-filters-container {
  width: 0;
  overflow: hidden;
  transition: $transition;
  background-color: $white;
  max-height: 0;

  &.visible {
    width: $filtersWidth;
    max-height: calc(100vh - 140px);
    overflow-y: auto;
    @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));
  }
}

.checkbox-normal {

  .ant-checkbox + span {
    font-size: 14px;
  }
}
