.activity-criteria-norms {

  .ant-collapse {
    margin-bottom: 15px;
    background-color: transparent;

    .ant-collapse-header {
      background-color: $grey-light;
      border-radius: $inputBorderRadius!important;
    }

    .ant-collapse-content-box {
      padding-right: 0;
    }
  }
}

.activity-criteria-sections {

  .ant-collapse {

    .ant-collapse-content-box {
      padding-right: 16px;
    }
  }
}

.graded {

  td {
    background-color: $lightSuccess;
  }
}

.not-graded {

  td {
    background-color: $lightDanger;
  }
}

td.p-l-r-0 {
  padding-left: 0!important;
  padding-right: 0!important;
}
