textarea.ant-input {
  @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));
}

.ant-input-textarea-show-count {
  position: relative;

  &:after {
    position: absolute;
    left: 0;
    bottom: -20px;
    font-size: 14px;
    line-height: 1;
    font-weight: 300;
  }
}
