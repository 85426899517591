.document-notes {

  .color-square {
    min-width: 12px;
  }

  .text {
    line-height: 14px;
  }

  label {
    margin-bottom: 5px;
  }
}

.hidden-section {
  display: none;
}

.template-row-criteria-tasks-table{
  margin-bottom: 100px;
}
