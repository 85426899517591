.user-name-avatar {
  display: flex;
  align-items: center;

  i {
    color: $white;
    margin-left: 8px;
  }
}

.dropdown-user-overlay {
  box-shadow: $shadow-dropdown;
  background-color: $white;
  top: 68px !important;
  width: 270px;
  padding: 8px 0;
  cursor: default;
}

.duo-profile {
  padding: 10px 15px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  .duo-avatar {
    min-width: 90px;
    width: 90px;
    flex: 0 0 90px;
  }

  .duo-right {
    flex: 1;
    overflow: hidden;

    .text-muted,
    h5 {
      margin: 0;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    }

    .text-muted {
      margin-bottom: 5px;
    }
  }
}

.duo-logout {
  border-top: 1px solid $border-color;
  padding-top: 5px;

  a {
    align-items: center;
    display: flex;
    width: 100%;
    padding: 9px 15px;
    transition: $transition;
    font-weight: 300;

    &:hover {
      background-color: $grey-light;
    }

    i {
      margin-left: 0;
      margin-right: 5px;
      color: $primary;
    }

    .preloader {
      display: inline-flex;
      margin-right: 5px;
      height: 20px;

      .ant-spin-dot {
        font-size: 14px !important;
      }
    }
  }
}
