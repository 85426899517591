.checkbox-search-options {
  position: relative;
  height: 150px;
  overflow: auto;
  @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));
  border: 1px solid $inputBorderColor;
  border-radius: $inputBorderRadius;
  padding: 10px;
}

.checkbox-search {

  .ant-btn-icon {
    height: 40px;
    width: 40px;
    min-width: 40px;
  }

  .ant-checkbox-wrapper {
    margin: 0;
    width: 100%;
    font-weight: 300;
  }
}

.ant-form-item-has-error {

  .checkbox-search {
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
      border-color: $inputBorderColor;
    }
  }

  .checkbox-search-options {
    border-color: $danger;
  }
}

.mass-duplication-wrap {
  .checkbox-search-options {
    height: fit-content;
  }
}
.checkbox-search-options.large-wrap-no-filter {
  height: 200px;
}
