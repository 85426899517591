.ant-tooltip {
  font-family: $fontFamily;
}

.ant-tooltip-inner {
  font-size: 12px;
  color: $white;
  padding: 3px 7px;
  min-height: 25px;
}
