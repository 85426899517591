.activity-view-content {
  transition: opacity .2s ease-in;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    user-select: none;

    .ant-collapse-expand-icon {
      order: 2;
      margin-left: auto;

      .ant-collapse-arrow {
        margin-right: 0;

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &.loading {
    opacity: .7;
  }
}

.activity-collapse-header {
  display: flex;
  align-items: center;

  .status {
    margin-right: 10px;
    font-size: 14px;
    color: $grey;
    line-height: 1;

    &.active {
      color: $success;
    }

    i {
      display: block;
    }
  }

  .info {
    margin-left: 10px;
    font-size: 14px;
    color: $grey;
    line-height: 1;

    i {
      display: block;
    }
  }
}

.activity-collapse-item {

  .ant-collapse-header-text {
    width: 100%;
  }

  &.completed {

    .activity-collapse-header {

      .status {
        color: $success;
      }
    }
  }
}

.error {

  > .ant-collapse,
  > .ant-collapse > .ant-collapse-item {
    border-color: $danger;
  }
}
