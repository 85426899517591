.ant-progress {
  font-size: 10px;
  line-height: 1;
}

.ant-progress-bg {
  height: 6px!important;
  background-color: $secondary;
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-size: 1rem 1rem;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

