.status-color-box {
  display: flex;
  align-items: center;

  .color {
    margin-right: 5px;
    min-width: 10px;
    width: 10px;
    height: 10px;
  }

  .name {
    width: calc(100% - 15px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.modal-norm-details {

  .ant-table-cell {
    vertical-align: baseline;
  }

  .ant-table-tbody td {
    border-bottom: 0;
  }
}

.norm-details-table {
  border: 1px solid $grey-light2;
  padding: 10px;
}

.root-cause-item {
  padding: 3px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $grey-light;
  margin-bottom: 5px;
  border-radius: $inputBorderRadius;

  &:last-child {
    margin-bottom: 0;
  }

  .right {
    margin-left: 10px;

    i {
      font-size: 14px;
      margin-left: 10px;
    }
  }
}
