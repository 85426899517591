.layout-sidebar {
  background-color: $white;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  transition: $transition;
  z-index: 10;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  & + .site-layout {
    transition: $transition;
    padding-left: 240px;
    padding-top: 68px;
    min-height: 100vh;

    @media #{$media-down-md} {
      padding-left: 0 !important;
    }
  }
}

.navigation-collapsed {
  .layout-header {
    width: calc(100% - 60px);
  }

  .layout-sidebar {
    @media #{$media-down-md} {
      width: 0 !important;
      min-width: 0 !important;
      max-width: 0 !important;
      flex: 0 0 0 !important;
    }

    & + .site-layout {
      padding-left: 60px;
    }

    .app-logo {
      .big-logo {
        display: none;
      }

      .small-logo {
        display: block;
      }
    }
  }
}

.layout-sidebar-navigation-container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 0;
  width: 100%;
  height: 100vh;

  .app-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 68px;
    width: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .small-logo {
      display: none;
      width: 35px;
      height: 35px;
    }

    .big-logo {
      width: 200px;
      height: 50px;
    }
  }
}

.navigation-scroll-container {
  height: calc(100vh - 80px) !important;

  @media #{$media-down-md} {
    box-shadow: 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
}
