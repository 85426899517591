.signature-input {
  position: relative;
  display: flex;
  border: 1px solid $inputBorderColor;
  border-radius: $inputBorderRadius;
  width: 500px;
  height: 200px;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .actions {
    position: absolute;
    right: 10px;
    top: 6px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.signature-placeholder {
  font-weight: 300;
  line-height: 1.3;
}

.signature-container {

  .ant-upload {
    font-size: 16px;
  }

  .ant-upload-picture-card-wrapper {
    width: auto;
    line-height: 22px;

    .ant-upload.ant-upload-select-picture-card {
      width: auto;
      height: auto;
      margin: 0;
      border: 0;
      background-color: transparent;
    }
  }
}
