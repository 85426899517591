.btn-close-popover {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  font-size: 16px;
}

.activity-popover{
  width: 700px;

  .ant-popover-inner-content {
    padding: 15px;
  }

  .activity-popover-title{
    max-width: calc(100% - 35px);

    h4 {
      margin-bottom: 0;
    }
  }
}
.course-popover{
  width: 500px;

  .ant-popover-inner-content {
    padding: 15px;
  }

  h4 {
    margin-bottom: 0;
  }
}
.activity-popover-content,
.course-popover-content{
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  letter-spacing: -0.5px;
}
.course-popover-column{
  width: calc(50% - 10px);
}
.activity-popover-column{
  width: calc(25% - 10px);
}
.course-popover-column,
.activity-popover-column{
  .column-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      line-height: 1.2;
      margin-bottom: 5px;

      i {
        margin-right: 5px;
        color: $primary;
      }
    }
  }

  .column-content {
    line-height: 1.2;
  }

  .auditor-contact {
    margin-top: 5px;

    a {
      font-size: 14px;
      margin-right: 10px;
    }
  }

  .show-more-list .item.active {
    margin-bottom: 0;
  }
}

.activity-popover-footer,
.course-popover-footer{
  display: flex;
  margin-top: 20px;

  .right {
    margin-left: auto;
  }

  a {
    font-size: 16px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
