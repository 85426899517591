.ant-alert {
  padding: 16px;
  border-radius: 20px;
  margin-bottom: 15px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.fs-16 {
  .ant-alert-message {
    font-size: 16px;
    line-height: 22px;
  }
}

.ant-alert-message {
  font-family: $fontFamily;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: $textColorDarken;
}

.ant-alert-success {
  background-color: $lightSuccess;
  border: 1px solid $success;
}

.ant-alert-error {
  background-color: $lightDanger;
  border: 1px solid $danger;
}
