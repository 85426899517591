.ant-select {
  width: 100%;
  color: $textColor;

  &:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $primary;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $primary;
    box-shadow: none;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: lighten($primary, 28%);
  color: $textColorDarken;
  font-weight: 500;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: $inputBorderRadius;
}

.rc-virtual-list-holder {
  @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  font-weight: 300;
}

.ant-select-lg {

  .ant-select-selection-placeholder {
    font-size: 16px;
  }
}

.ant-select-selection-placeholder {
  font-family: $fontFamily;
  color: $grey;
  font-weight: 300;
  opacity: 0.8;
}

.ant-select-clear {
  width: 16px;
  height: 16px;
  margin-top: -8px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.ant-select-multiple .ant-select-selection-item-content {
  font-weight: 300;
}

.ant-select-dropdown {
  z-index: 998;
}

.ant-select-multiple .ant-select-selection-placeholder {
  right: 18px;
}
