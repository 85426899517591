.user-table-card {
  display: flex;
  align-items: center;

  .container-avatar {
    width: 40px;
    min-width: 40px;
  }

  .text-muted {
    font-size: 12px;
    font-weight: 400;
  }

  .right-part {
    flex: 1;
    width: calc(100% - 55px);

    h6 {
      margin: 0;
    }

    h6,
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .text-muted {
      margin-top: 5px;
    }
  }
}

.user-active-tooltip {
  min-width: 200px;
  padding: 5px 10px;

  .status {
    span {
      margin-right: 3px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .date-item {
    display: flex;

    span {
      &:first-child {
        min-width: 50px;
      }
    }
  }
}
