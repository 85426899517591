@import "cropperjs";

.image-to-crop {
  display: block;
  max-width: 100%;
}

.cropper-image-container {
  max-width: 100%;
}

.cropper-toolbar {
  margin-top: 20px;

  .ant-btn {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.cropper-view-box {
  outline: none;
  box-shadow: 0 0 1px 1px rgba(51, 153, 255, 0.75);
}

.cropper-container {
  &.round {
    .cropper-face {
      background-color: transparent;
    }

    .cropper-crop-box,
    .cropper-view-box {
      border-radius: 50%;
    }
  }
}

.cropper-outer-container {
  position: relative;
}

.cropper-loading-image {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
}

.custom-cropper-modal {
  .ant-modal {
    width: 700px !important;
    max-width: 100%;

    .ant-modal-body {
      padding: 16px;
    }

    .modal-close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      background-color: #fff;
      top: -12px;
      right: -11px;

      &:hover {
        opacity: 1;
      }
    }
  }
}
