.norm-modal-form {

  .ant-collapse-header-text {
    width: calc(100% - 23px);
  }

  .fix-text {
    margin-top: -20px;
  }
}

.norm-form-header {
  display: flex;
  align-items: center;

  .right {
    margin-left: 20px;
  }

  .left {
    margin-right: auto;
    max-width: calc(100% - 38px)
  }
}

.norm-form-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  .right {
    margin-left: 20px;
  }

  .left {
    margin-right: auto;
    max-width: calc(100% - 40px)
  }
}
