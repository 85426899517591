.qualifications-counter {

  .timeline-item {
    width: 200px;
    max-width: 200px;
  }

  .no {
    width: 53px;
    height: 53px;
    letter-spacing: -1px;
  }
}
