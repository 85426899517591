.layout-header {
  position: fixed;
  display: flex;
  align-items: center;
  color: blue;
  height: 68px;
  padding: 0;
  z-index: 999;
  right: 0;
  top: 0;
  width: calc(100% - 240px);
  transition: $transition;
  background-color: $primary;

  @media #{$media-down-md} {
    width: 100% !important;
  }

  .header-small-logo {
    display: none;

    @media #{$media-down-md} {
      height: 68px;
      width: 60px;
      padding: 10px;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .trigger-sidebar {
    i {
      &:last-child {
        display: none;

        @media #{$media-down-md} {
          display: block;
        }
      }

      &:first-child {
        @media #{$media-down-md} {
          display: none;
        }
      }
    }
  }
}

.header-left-part {
  flex: 1;
}
