.centered-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 24px;
}

.auth-footer {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  background: $white;
  padding: 13.5px 15px;
  display: flex;
  position: fixed;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;

  a {
    font-size: 14px;
    font-weight: 300;
  }
}

.auth-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.auth-inner-container {
  border-radius: 20px;
  background-color: #fff;
  margin-left: -24px;
  width: 100%;
  max-width: 400px;
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media #{$media-down-md} {
    margin-left: 0;
  }

  .auth-header-form {
    margin-bottom: 40px;

    img {
      max-width: 100%;
    }
  }

  .auth-form {
    width: 100%;

    .ant-form-item {
      margin-bottom: 8px;
    }
  }

  .ant-alert {
    width: 100%;
  }
}

.sub-form-links {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    font-weight: 300;
    font-size: 14px;
  }
}
