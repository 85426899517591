.antd-custom-notification {
  width: auto;
  min-width: 300px;
  padding: 15px;

  .ant-notification-notice-close {
    top: 7px;
    right: 7px;
    font-size: 10px;
  }

  .ant-notification-notice-icon {
    margin-left: 0;
  }

  &.no-description {
    .ant-notification-notice-with-icon {
      .ant-notification-notice-message {
        margin-bottom: 0;
        margin-left: 35px;
      }
    }
  }

  .ant-notification-notice-message {
    color: $textColorDarken;
    font-weight: 300;
    font-size: 16px;
  }
}

.ant-notification-bottom.bottom-z-index {
  inset: auto auto 24px 50%;
  transform: translateX(-50%);
  right: auto!important;
  z-index: 99999999999;
}
