.ant-form {
  font-family: $fontFamily;
  font-size: 16px;
  line-height: 20px;
}

.ant-form-item {
  color: $textColor;
  font-size: 16px;
  margin-bottom: 15px;
}

.hide-required-mark {

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: none!important;
  }
}

.ant-form-item-label {
  flex: 0 0 25%;
  max-width: 25%;
  text-align: left;
  padding-top: 5px;
  padding-right: 10px;
  font-weight: 300;

  > label {
    display: inline;
    font-family: $fontFamily;
    font-size: 16px;
    color: $textColor;
    max-width: 100%;
    white-space: normal;

    &:after,
    &:before {
      display: none !important;
    }

    &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline !important;
      content: "*";
      margin-right: 0;
    }
  }
}

.form-field-with-children {
  position: relative;

  .datepicker-tooltip {
    right: 35px;
    color: $grey-darken;
    font-size: 14px;
    cursor: pointer;
  }
}
