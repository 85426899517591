/* Fonts */
@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900);
@import-normalize;
@import "~antd/dist/antd.css";
@import "variables";
@import "./themify-icons";
@import "./helpers/all";
@import "./general/all";
@import "./components";

body {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  color: $textColor;
}
