.custom-tag {
  border-radius: 12px;
  font-family: $fontFamily;
  color: $textColorDarken;
  border-color: $textColorDarken;
  background-color: $lightDarken;
  margin: 2px 5px 2px 0;
  white-space: normal;
  max-width: 100%;

  &.tag-secondary {
    background-color: $lightInfo;
    border-color: $info;
  }

  &.tag-primary {
    background-color: $lightSuccess;
    border-color: $success;
  }

  &.tag-danger {
    background-color: $lightDanger;
    border-color: $danger;
  }

  &.tag-warning {
    background-color: $lightWarning;
    border-color: $warning;
  }
}
