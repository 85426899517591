.dashboard-container {
  display: flex;
  flex-direction: column;
}

.dashboard-welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  flex: 1;

  img {
    max-width: 100%;
    max-height: 250px!important;
  }
}

.dashboard-boxes {
  font-size: 17px;
  font-weight: 300;

  svg {
    max-width: 100%;
  }

  .preview-guide {
    border: 1px solid $lightDarken;
    max-height: 250px;
  }

  .languages-img-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    img {
      max-height: 25px;
      margin: 0 30px 15px 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.clear-filters-actions-top .table-right-actions {
  margin-top: -65px;
}

.clear-filters-actions-bottom .table-right-actions {
  margin-bottom: 20px;
}
