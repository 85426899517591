.show-more-list {
  &.bold {
    .item {
      font-weight: 500;
      font-size: 13px;
    }
  }

  .item {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity, background-color 0.1s ease-in-out;
    color: $textColorDarken;
    line-height: 1.2;

    &:hover {
      background-color: $white;
    }

    &.active {
      opacity: 1;
      overflow: visible;
      max-height: 1000px;
      margin-bottom: 4px;
      padding: 3px 5px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .show-more-list-trigger {
    cursor: pointer;
    color: $primary;
    font-size: 12px;
    font-weight: 400;

    span {
      display: flex;
      align-items: center;

      i {
        font-size: 8px;
      }
    }
  }
}
