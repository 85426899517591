.publication-view-sidebar-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto 0;
}

.confirmations-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 8px 0;
  background-color: #f7fcfd;

  .confirmations-element {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.qa-container {
  display: flex;
  flex-direction: column;

  .qa-answers {
    margin-top: 5px;
    ul {
      padding: 0 0 0 15px;

      li {
        margin-bottom: 5px;

        &.correct {
          color: #00a527;
        }
      }
    }
  }
}
