.evidences-table {

  .outer-table-container {
    margin-top: -10px;
    padding: 0;
    width: 100%;
  }
}

.activity-criteria-sections {
  .table-vertical-top {
    > tbody {
      .evidences-table {
        margin-bottom: 40px;
      }

      &:last-child {
        .evidences-table {
          margin-bottom: 10px;
        }
      }
    }
  }
}
