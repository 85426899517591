.ant-switch {
  background-color: $grey;
  border-radius: 48px;
  height: 24px;
  line-height: 24px;

  &:focus {
    box-shadow: none;
  }

  &:active {
    .ant-switch-handle::before {
      right: 0 !important;
      left: 0 !important;
    }
  }

  &.ant-switch-checked {
    background-color: $primary;

    .ant-switch-handle {
      left: calc(100% - 20px - 2px);
    }
  }

  .ant-switch-handle {
    width: 20px;
    height: 20px;

    &:before {
      border-radius: 20px;
      background-color: $white;
      box-shadow: none;
    }
  }

  .ant-click-animating-node {
    display: none;
  }
}
