.columns-bordered {

  .ant-col {
    border-left: 1px solid $grey-light3;

    &:first-child {
      border-left: 0;
    }

    &.md-no-border {
      @media #{$media-down-md} {
        border: 0!important;
      }
    }
  }
}
