.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: visible;
}

.ant-steps-item:last-child {
  flex: 1;
}

.ant-steps-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-steps-item-title,
.ant-steps-item-description {
  padding: 0;
  position: static;
  white-space: normal;
}

.ant-steps-item-title {
  line-height: 22px;
  font-weight: 300;
}

.ant-steps-item-content {
  text-align: center;
  margin-top: 5px;
}

.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon,
.ant-steps-item-icon {
  font-weight: 300;
  font-family: $fontFamily;
  width: 45px;
  height: 45px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  background: #fff;
  z-index: 10;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.ant-steps-item-title::after {
  left: 50%;
  top: 22.5px;
  width: 100%;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  display: none !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: $primary;
}

.ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-process .ant-steps-item-icon {
  border-color: $primary;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon {
  border-color: $primary;
}

.ant-steps-item-icon .ant-steps-icon,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-subtitle,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-description,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: $primary;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: $primary;
}

.steps-header {
  margin-bottom: 20px;
  overflow: auto;
}

.steps-actions {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  .ant-btn {
    margin-bottom: 10px;
    margin-left: 20px;
    transition: all 0.15s ease-in-out, margin-left 0s ease-in-out;

    &:first-child {
      margin-left: 0;
    }
  }
}

.step-content {
  display: none;

  &.visible {
    display: block;
  }
}
