.group-three-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > div {
    width: calc(33.333% - 10px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media #{$media-down-md} {
      width: 100%;
    }
  }
}
