.ant-input-search {
  > .ant-input-group {
    display: flex;

    > .ant-input-group-addon:last-child {
      display: flex;

      .ant-input-search-button:not(.ant-btn-primary) {
        min-height: auto;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 35px;

        .anticon {
          position: static !important;
          transform: none;
        }
      }
    }
  }
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $primary;
  box-shadow: none;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $primary;
}

.ant-input:hover {
  border-color: $primary;
}

.ant-input:focus,
.ant-input-focused {
  box-shadow: none;
  border-color: $primary;
}

.ant-input-affix-wrapper {
  border-radius: $inputBorderRadius;

  .ant-input {
    border-radius: 0;
  }
}

.ant-input {
  border-radius: $inputBorderRadius;
  font-weight: 300;
  color: $textColor;
  line-height: 1;

  @include placeholder {
    font-family: $fontFamily;
    font-size: 14px;
    color: $grey;
    font-weight: 300;
    opacity: 0.8;
  }

  &.ant-input-lg {
    padding: 9.5px 11px;

    @include placeholder {
      font-size: 16px;
      color: $grey;
    }
  }
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  box-shadow: none;
  border-color: $danger;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  border-color: $danger;
}

.ant-form-item-explain-error {
  font-weight: 300;
  color: $danger;
  font-size: 14px;
}

.ant-form-item-has-error {

  .input-upload {
    border-color: $danger;
  }
}

.ant-input-number {
  width: 100%;
  border-radius: $inputBorderRadius;

  &:focus,
  &.ant-input-number-focused {
    box-shadow: none;
    border-color: $primary;
  }

  &:hover {
    border-color: $primary;
  }

  input {
    font-weight: 300;
    color: $textColor;
    line-height: 1;

    @include placeholder {
      font-family: $fontFamily;
      color: $grey;
      font-weight: 300;
      opacity: 0.8;
      font-size: 16px;
    }

    &[disabled] {
      color: rgba(0,0,0,0.25);

    }
  }
}

.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: $primary;
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  box-shadow: none;
  border-color: $danger;
}
