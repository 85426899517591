.multiple-roots {
  .orgchart > ul > .oc-hierarchy {
    > .oc-node {
      display: none;

      & + ul > li.oc-hierarchy {
        &:before {
          display: none;
        }

        > .oc-node {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.chart-card {
  display: flex;
  width: 300px;
  height: 110px;
  padding: 12px 15px 15px 15px;
  margin: 0 auto;
  background-color: #d6ebf2;
  transition: $transition;
  user-select: none;
  text-align: left;
  border: 1px solid transparent;

  &:hover {
    background-color: #eff7fa;
  }

  &.highlighted {
    border: 1px solid $primary;
    background-color: $lightSuccess;
  }

  .avatar {
    width: 30px;
    margin-top: 3px;
    margin-right: 10px;
  }

  .details {
    width: calc(100% - 40px);

    > div {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &.position {
        line-height: 14px;
      }
    }
  }

  .name {
    a {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 5px;
      line-height: 18px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }
  }

  .position {
    font-size: 13px;
    margin-bottom: 7px;
  }

  .phone,
  .email {
    display: flex;
    align-items: center;

    i,
    span {
      display: flex;
      min-width: 25px;
      width: 25px;
      font-size: 14px;
    }

    a {
      max-width: calc(100% - 25px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  a {
    color: $textColorDarken;
    font-size: 13px;

    &:hover {
      color: $textColor;
    }
  }
}

.chart-outer-container {
  position: relative;

  .chart-loading {
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
  }
}

.chart-inner-container {
  transition: $transition;
  min-height: 120px;

  &.loading {
    opacity: 0.3;
  }

  .orgchart {
    background-image: none;
    border: 0;
    padding: 0;
  }

  .orgchart-container {
    border: 0;
    margin: 0 auto;
    height: 100%;
    @include scrollbars(10px, rgba($grey, 0.7), rgba($grey, 0.1));
  }

  .orgchart ul li .oc-node:not(:only-child)::after {
    width: 1px;
    background-color: $grey;
  }

  .orgchart > ul > li > ul li > .oc-node::before {
    top: -12px;
    width: 1px;
    background-color: $grey;
  }

  .orgchart > ul > li > ul li::before {
    border-top: 1px solid $grey;
  }

  .orgchart ul li .oc-node.selected,
  .orgchart ul li .oc-node:hover {
    background-color: $white;
  }
}

.orgchart-wrapper {
  display: flex;
  flex-direction: column;

  .orgchart-filters {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    margin-bottom: 30px;

    .ant-select {
      min-width: 200px;
      max-width: 300px;
    }
  }
}
