.confirmation-btn {
  margin-left: 10px;
}

.question-container {
  display: flex;
  flex-direction: column;

  .answers-container {
    display: flex;
    flex-direction: column;
    margin: 15px 0;

    .question-answer {
      margin-left: 0;
    }
  }
}

.publication-error-tag {
  width: 100%;
  padding: 10px 20px 10px;
  margin-bottom: 20px;
}
