.ant-collapse {
  color: $textColor;
  font-size: 16px;
  font-weight: 300;
  border-color: $inputBorderColor;
  border-radius: $inputBorderRadius;
  background-color: $grey-light-lighter;

  & > .ant-collapse-item {
    border-color: $inputBorderColor;

    > .ant-collapse-header {
      color: $textColor;
    }
  }

  & > .ant-collapse-item:last-child,
  & > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0 0 $inputBorderRadius $inputBorderRadius;
  }

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 $inputBorderRadius $inputBorderRadius;
  }

  &.bg-white {
    background-color: $white;

    .ant-collapse-content {
      border-top: 0;
    }
  }
}
