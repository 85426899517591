.activity-view-header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .left {
    flex: 1;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.activity-view-actions {
  display: flex;
  flex-wrap: wrap;

  > button {
    margin-left: 10px;
    margin-bottom: 10px;
    min-width: 150px;
  }
}
