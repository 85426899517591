.popover-color-picker {
  .ant-popover-inner-content {
    padding: 3px;
  }

  .chrome-picker {
    box-shadow: none !important;
  }
}

.color-picker-input {
  position: relative;

  .square {
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    width: 40px;
    border-bottom-right-radius: $inputBorderRadius;
    border-top-right-radius: $inputBorderRadius;
  }
}
