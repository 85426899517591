.notifications-trigger {
  position: relative;

  .notify {
    position: absolute;
    right: 2px;
    top: 19px;
  }
}

.notifications-dropdown-overlay {
  box-shadow: $shadow-dropdown;
  background-color: $white;
  top: 50px !important;
  cursor: default;

  .notification {
    min-width: 300px;
  }

  header {
    font-weight: 500;
    padding: 11px 20px 15px;
    border-bottom: 1px solid $border-color;
    font-size: $font-size-16;
    color: $textColorDarken;
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 20px 15px;
    border-top: 1px solid $border-color;
    z-index: 2;
    background: $white;
    position: relative;
    margin-top: -1px;
  }

  .no-data-to-show {
    height: 100%;
  }

  .content {
    position: relative;

    ul {
      margin: 0;
      padding: 0;
      transition: $transition;

      li {
        list-style: none;

        &:last-child {
          .notification-dropdown-item {
            border-bottom: 0;
          }
        }
      }
    }
  }

  .preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    & + div ul {
      opacity: 0.4;
    }
  }
}

.notification-dropdown-item {
  display: flex;
  cursor: pointer;
  padding: 9px 15px;
  border-bottom: 1px solid $border-color;
  align-items: center;
  transition: $transition;
  user-select: none;

  &:hover {
    background-color: $grey-light;
  }

  &.disabled {
    background-color: $border-color;
    cursor: not-allowed;
  }

  .custom-icon {
    padding: 13px;
  }
}

.notification-dropdown-item-content {
  width: calc(100% - 50px);
}

.notifications-badge {
  color: rgba($white, 0.8);
  font-size: 17px;
}

.ant-badge-count {
  background: $danger;
}
