// Colors
$black: #000000;
$white: #ffffff;
$linkColor: #336BB4;

$primary: #87ca97;
$secondary: #63c9d9;

$info: #009efb;
$lightInfo: #cfecfe;

$success: #55ce63;
$lightSuccess: #e8fdeb;

$darken: #212529;
$lightDarken: #ddd;

$warning: #ffbc34;
$lightWarning: #fff8ec;

$danger: #f62d51;
$lightDanger: #f9e7eb;

$textColor: #2c2b2e;
$textColorDarken: #212529;

$grey: #90a4ae;
$grey-light: #f7f9f9;
$grey-light-lighter: #fcfcfc;
$grey-light2: #e8edef;
$grey-light3: #dae0e4;
$grey-darken: #46575f;
$text-muted: #90a4ae;

// Transitions
$transition: all 0.1s ease-in-out;

// Font sizes
$font-size-16: 16px;
$font-size-12: 12px;

// Shadows
$shadow-dropdown: rgba(0, 0, 0, 0.05) 0 3px 12px 0;
$shadow-popover: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

// Borders
$border-color: rgba(120, 130, 140, 0.13);
$border-table: #dedede;

// Inputs border radius
$inputBorderRadius: 4px;
$inputBorderColor: #d9d9d9;

// Default font-family
$fontFamily: Rubik, sans-serif;

// Responsive breakpoints
$media-down-xxl: "(max-width: 1400px)";
$media-down-xl: "(max-width: 1200px)";
$media-down-x: "(max-width: 1024px)";
$media-down-lg: "(max-width: 992px)";
$media-down-md: "(max-width: 767px)";
$media-down-sm: "(max-width: 576px)";
$media-down-xs: "(max-width: 540px)";
