.filter-component-container {
  margin: 20px 25px;
  padding: 10px 10px;
  border-radius: 4px;
  background: transparent;
  border: 1px solid $border-table;
  position: relative;

  &.empty {
    padding-bottom: 15px;

    > .header:before {
      display: none;
    }

    > .body {

      > .filter-component-container {

        &:before {
          top: -37px;
          height: calc(50% + 45px);
        }
      }
    }
  }

  &.first {
    margin: 0;

    > .header {
      .or {
        display: none
      }

      .and {
        border-radius: $inputBorderRadius;
      }

      .ant-btn-danger {
        display: none;
      }
    }
  }

  &.is-hover {

    > .header .actions {
      opacity: 1;
      user-select: all;
    }
  }

  .body {

    .filter-component-container {
      transition: box-shadow 0.1s ease-in-out;

      &:hover {
        box-shadow: 0 -1px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.15);
      }

      &:after {
        content: "";
        position: absolute;
        left: -26px;
        width: 21px;
        height: 50%;
        border-color: #87CA97;
        border-style: solid;
        top: 50%;
        border-width: 0 0 0 2px;
      }

      &:before {
        content: "";
        position: absolute;
        left: -26px;
        width: 25px;
        height: calc(50% + 47px);
        border-color: $primary;
        border-style: solid;
        top: -45px;
        border-width: 0 0 2px 2px;
      }

      &:last-of-type {

        &:after {
          display: none;
        }
      }
    }
  }

  .header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: -10px;

    &:before {
      content: "";
      background-color: $primary;
      width: 2px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 20px;
    }
  }

  .operations {
    display: flex;
    margin-right: 10px;
    z-index: 1;

    > div {
      display: flex;
      padding: 7px 12px;
      font-size: 14px;
      cursor: pointer;
      line-height: 1;
      background-color: lighten($primary, 10%);
      text-align: center;
      color: $white;

      &.and {
        border-bottom-left-radius: 0;
        border-top-left-radius: $inputBorderRadius;
      }

      &.or {
        border-bottom-right-radius: $inputBorderRadius;
        border-top-right-radius: $inputBorderRadius;
      }

      &.active {
        background-color: $primary;
      }
    }
  }

  .actions {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    opacity: 0;
    transition: $transition;
    user-select: none;

    .ant-btn {
      margin-left: 10px;
      margin-bottom: 5px;
    }
  }

  .filter-row {
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    position: relative;
    margin-top: 10px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 30px;
    transition: background-color 0.1s ease-in-out;

    &:hover {
      background-color: $grey-light;
      border-radius: $inputBorderRadius;

      .ant-btn-danger {
        opacity: 1;
        user-select: all;
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: -10px;
      width: 15px;
      height: calc(50% + 40px);
      border-color: $primary;
      border-style: solid;
      top: -39px;
      border-width: 0 0 2px 2px;
    }

    &:first-child {
      margin-top: 25px;
    }

    .column-input-container {
      display: flex;
      width: calc(100% - 10px);
      overflow-x: auto;
      @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));
    }

    .column-input {
      max-width: 200px;
      min-width: 100px;
      margin-bottom: 5px;
      flex: 1;

      ~ .column-input {
        margin-left: 10px;
      }
    }

    .ant-btn-danger {
      transition: $transition;
      user-select: none;
      position: absolute;
      opacity: 0;
      right: 5px;
      top: 12px;
    }
  }
}

.hide-actions {
  .actions, .remove-row {
    display: none;
  }
}
