.activity-field-input {
  display: flex;
  align-items: center;

  &.two-btn-icons {

    .ant-select {
      max-width: calc(100% - 44px)
    }
  }

  .ant-select {
    max-width: calc(100% - 22px)
  }

  .ant-btn {
    margin-left: 5px;
  }
}

.activity-field-value {
  margin-top: 10px;

  .value {
    display: flex;
    margin-top: 5px;
    align-items: center;
    padding: 4px 10px;
    border-radius: $inputBorderRadius;
    font-size: 14px;
    font-weight: 400;
    background-color: $grey-light;
    transition: $transition;

    > span {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ti-close {
      cursor: pointer;
    }

    &:hover {
      background-color: $grey-light2;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
