.image-upload-container {
  display: inline-flex;

  .ant-upload.ant-upload-select-picture-card {
    width: auto;
    height: auto;
    margin: 0;
    background-color: transparent;
    border: 0;

    .ant-upload {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .placeholder {
      margin-bottom: 15px;
    }

    .upload-label {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }

      span {
        font-family: $fontFamily;
        font-size: 16px;
        color: blue;
      }
    }
  }
}

.input-upload {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: $inputBorderRadius;
  font-weight: 300;
  color: $textColor;
  border: 1px solid $inputBorderColor;
  font-size: 16px;
  cursor: text;
  transition: $transition;
  user-select: none;

  &:hover {
    border-color: $primary;

    .clear-upload {
      display: flex;
    }
  }

  .clear-upload {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.25);
    display: none;
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    transition: $transition;
  }

  .file-name,
  .placeholder {
    padding: 9.5px 11px;
    line-height: 19px;
    font-family: $fontFamily;
    font-size: 16px;
    color: $grey;
    font-weight: 300;
    opacity: 0.8;
  }

  .file-name {
    color: $textColor;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: auto;
    margin: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    cursor: text;
    text-align: left;
    display: block;

    > .ant-upload {
      justify-content: flex-start;
    }
  }

  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }

  .delete {
    color: rgba(0, 0, 0, 0.25);
    margin: 0 0 0 10px;
    cursor: pointer;
  }

  .clear, .delete {
    &:hover {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.file-uploader {
  position: relative;
  width: 100%;

  .ant-upload {
    width: 100%;
    display: block;
  }

  .preloader {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    .anticon {
      font-size: 18px !important;
    }
  }
}
