.activities-views-links {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  a {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    min-width: 105px;
    border: 1px solid $inputBorderColor;
    padding: 11px 10px;
    color: $textColor;
    line-height: 1;

    &:first-child {
      border-top-left-radius: $inputBorderRadius;
      border-bottom-left-radius: $inputBorderRadius;
    }

    &:last-child {
      border-top-right-radius: $inputBorderRadius;
      border-bottom-right-radius: $inputBorderRadius;
      margin-left: -1px;
    }

    &.active {
      background-color: $grey-light2;
    }

    i {
      margin-top: -2px;
      margin-right: 5px;
      font-size: 17px;
    }
  }
}
