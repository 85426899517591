$btn-primary: $primary;
$btn-secondary: $secondary;
$btn-danger: $danger;
$btn-default: $grey;
$btn-info: $info;

// Disabled antd focus buttons animations
[ant-click-animating-without-extra-node]:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

.ant-btn {
  border: 0;
  box-shadow: none !important;
  text-shadow: none !important;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 30px;
  text-align: center;
  line-height: 1;
  font-size: 14px;
  font-family: $fontFamily;
  font-weight: 300;
  transition: all 0.15s ease-in-out;
  height: auto;
  min-height: 40px;
  border-radius: 20px;
  background-color: $btn-default;
  color: #fff;

  &:focus,
  &:active {
    background-color: $btn-default;
    color: #fff;
  }

  &:hover {
    background-color: $btn-default;
    color: #fff;
    opacity: 0.7;
  }

  i {
    margin: 0 8px 0 0;
    font-size: 12px;
  }

  &.icon-right {
    flex-direction: row-reverse;

    i {
      margin: 0 0 0 8px;
    }
  }

  &.icon-center {
    i {
      margin: 0
    }
  }

  &.large {
    i {
      font-size: 18px;
    }
  }

  &.ant-btn-loading {
    &.icon-right {
      .anticon {
        padding-left: 8px;
        padding-right: 0;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}

a.ant-btn-sm,
.ant-btn-sm {
  min-height: auto;
  padding: 5px 10px !important;
  font-size: 12px;
  border-radius: 60px;
  line-height: 18px;
}

.ant-btn-md {
  min-height: auto;
  padding: 7px 15px !important;
  font-size: 14px;
  border-radius: 60px;
  line-height: 18px;

  i {
    margin-right: 3px;
  }
}

.ant-btn-lg {
  min-height: 48px;
}

.ant-btn-primary {
  background-color: $btn-primary;
  color: #fff;

  &:focus,
  &:active {
    background-color: $btn-primary;
  }

  &:hover {
    background-color: $btn-primary;
    opacity: 0.7;
  }

  &.disabled,
  &[disabled] {
    color: #fff !important;
    background-color: $btn-primary !important;
    opacity: 0.5;

    &:hover {
      background-color: $btn-primary;
      color: #fff;
    }
  }

  &.ant-btn-background-ghost {
    background-color: #fff;
    border: 1px solid $btn-primary;
    color: $btn-primary;

    &:focus,
    &:active {
      border-color: $btn-primary;
      background-color: #fff;
      color: $btn-primary;
    }

    &:hover {
      background-color: $btn-primary;
      border-color: $btn-primary;
      color: #fff;
      opacity: 0.7;
    }

    &.disabled,
    &[disabled] {
      color: $btn-primary;
      border-color: $btn-primary;
      background-color: #fff;
      opacity: 0.5;

      &:hover {
        color: $btn-primary;
        border-color: $btn-primary;
        background-color: #fff;
      }
    }
  }
}

.ant-btn-secondary {
  background-color: $btn-secondary;
  color: $white;

  &:focus,
  &:active {
    color: $white;
    background-color: $btn-secondary;
  }

  &:hover {
    color: $white;
    background-color: $btn-secondary;
    opacity: 0.7;
  }

  &.disabled,
  &[disabled] {
    color: $white;
    background-color: $btn-secondary;
    opacity: 0.5;

    &:hover {
      background-color: $btn-secondary;
      color: $white;
    }
  }

  &.ant-btn-background-ghost {
    background-color: #fff;
    border: 1px solid $btn-secondary;
    color: $btn-secondary;

    &:focus,
    &:active {
      border-color: $btn-secondary;
      background-color: #fff;
      color: $btn-secondary;
    }

    &:hover {
      background-color: $btn-secondary;
      border-color: $btn-secondary;
      color: #fff;
      opacity: 0.7;
    }

    &.disabled,
    &[disabled] {
      color: $btn-secondary;
      border-color: $btn-secondary;
      background-color: #fff;
      opacity: 0.5;

      &:hover {
        color: $btn-secondary;
        border-color: $btn-secondary;
        background-color: #fff;
      }
    }
  }
}

.ant-btn-info {
  background-color: $btn-info;
  color: $white;

  &:focus,
  &:active {
    color: $white;
    background-color: $btn-info;
  }

  &:hover {
    color: $white;
    background-color: $btn-info;
    opacity: 0.7;
  }

  &.disabled,
  &[disabled] {
    color: $white;
    background-color: $btn-info;
    opacity: 0.5;

    &:hover {
      background-color: $btn-info;
      color: $white;
    }
  }

  &.ant-btn-background-ghost {
    background-color: #fff;
    border: 1px solid $btn-info;
    color: $btn-info;

    &:focus,
    &:active {
      border-color: $btn-info;
      background-color: #fff;
      color: $btn-info;
    }

    &:hover {
      background-color: $btn-info;
      border-color: $btn-info;
      color: #fff;
      opacity: 0.7;
    }

    &.disabled,
    &[disabled] {
      color: $btn-info;
      border-color: $btn-info;
      background-color: #fff;
      opacity: 0.5;

      &:hover {
        color: $btn-info;
        border-color: $btn-info;
        background-color: #fff;
      }
    }
  }
}

.ant-btn-danger {
  background-color: $btn-danger;
  color: #fff;

  &:focus,
  &:active {
    color: #fff;
    background-color: $btn-danger;
  }

  &:hover {
    color: #fff;
    background-color: $btn-danger;
    opacity: 0.7;
  }

  &.disabled,
  &[disabled] {
    color: #fff;
    background-color: $btn-danger;
    opacity: 0.5;

    &:hover {
      background-color: $btn-danger;
      color: #fff;
    }
  }

  &.ant-btn-background-ghost {
    background-color: #fff;
    border: 1px solid $btn-danger;
    color: $btn-danger;

    &:focus,
    &:active {
      border-color: $btn-danger;
      background-color: #fff;
      color: $btn-danger;
    }

    &:hover {
      background-color: $btn-danger;
      border-color: $btn-danger;
      color: #fff;
      opacity: 1;
    }

    &[disabled] {
      color: $btn-danger;
      border-color: $btn-danger;
      background-color: #fff;
      opacity: 0.5;

      &:hover {
        color: $btn-danger;
        border-color: $btn-danger;
        background-color: #fff;
      }
    }
  }
}

.ant-btn-text {
  border: 0;
  color: $textColorDarken;
  background-color: transparent;
  padding: 5px 10px;

  &:focus,
  &:active {
    background-color: transparent;
    color: $textColorDarken;
  }

  &:hover {
    background-color: transparent;
    color: $textColorDarken;
    opacity: 1;
  }

  &.disabled,
  &[disabled] {
    color: $textColorDarken;
    opacity: 0.5;

    &:hover {
      color: $textColorDarken;
    }
  }
}

.ant-btn-default {
  background-color: $btn-default;
  color: #fff;

  &:focus,
  &:active {
    background-color: $btn-default;
  }

  &:hover {
    background-color: $btn-default;
    opacity: 0.7;
  }

  &.disabled,
  &[disabled] {
    color: #fff !important;
    background-color: $btn-default !important;
    opacity: 0.5;

    &:hover {
      background-color: $btn-default;
      color: #fff;
    }
  }
}

.ant-btn.ant-btn-icon {
  padding: 8px 10px !important;
  border-radius: $inputBorderRadius;
  height: auto;
  min-height: auto;

  &.ant-btn-sm {
    padding: 6px 6px !important;

    i {
      font-size: 12px;
    }
  }

  i {
    margin: 0;
    font-size: 17px;
  }
}

.ant-btn.ant-btn-ghost-icon {
  padding: 0;
  height: auto;
  min-height: auto;
  border-radius: 0;
  background-color: transparent;
  color: $primary;

  &.secondary {
    color: $secondary;
  }

  i {
    margin: 0;
    font-size: 17px;
  }

  &.disabled,
  &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
    cursor: not-allowed;
    background-color: transparent;
    opacity: .5;
  }
}
