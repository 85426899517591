.ant-picker {
  width: 100%;
  border-radius: $inputBorderRadius;
  color: $textColor;
  font-size: 16px;

  &.ant-picker-disabled {
    &.ant-picker:hover,
    &.ant-picker-focused {
      border-color: #d9d9d9;
    }
  }

  &.ant-picker-middle {
    padding: 4px 11px 4px;

    .ant-picker-input > input {
      font-size: 14px;

      @include placeholder {
        font-size: 14px;
      }
    }
  }
}

.ant-picker-input > input {
  color: $textColor;
  font-size: 16px;
  font-weight: 300;

  @include placeholder {
    font-family: $fontFamily;
    font-size: 16px;
    color: $grey;
    font-weight: 300;
    opacity: 0.8;
  }
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: $primary;
  box-shadow: none;
}

.ant-picker-status-error.ant-picker-focused,
.ant-picker-status-error.ant-picker:focus {
  box-shadow: none;
  border-color: $danger;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: $primary;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: $primary;
}

.ant-picker-today-btn {
  color: $primary;

  &:active {
    color: $primary;
  }

  &:hover {
    color: rgba($primary, 0.8);
  }
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: lighten($primary, 25%);
}

.ant-picker-header-view button:hover {
  color: $primary;
}

.ant-picker-time-panel-column {
  @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));
}

.ant-picker-range .ant-picker-active-bar {
  background: $primary;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: $lightSuccess;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: darken($lightSuccess, 7%);
}
