.anonymous-report-page {
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .box {
    padding: 0;
    width: 100%;
  }

  .auth-inner-container {
    max-width: none;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .auth-footer {
    margin-top: auto;
    position: static;
    width: 100%;
  }

  .auth-container {
    margin-bottom: 30px;
  }

  .logo {
    margin-bottom: 40px;
    border-bottom: 1px solid $border-color;
    padding: 10px;
    text-align: center;

    img {
      max-height: 50px;
      max-width: 100%;
    }
  }
}
