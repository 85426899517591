.ant-avatar {
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.ant-avatar-image) {
    background-color: $secondary;
  }

  .anticon svg {
    width: 0.5em;
  }

  .ant-avatar-string {
    line-height: 1;
    font-family: $fontFamily;
    font-weight: 300;
    font-size: 0.5em;
  }

  &.ant-avatar-square {
    border-radius: 5px;
  }
}
