.ant-tabs {
  color: $textColor;
  font-size: 16px;
  font-weight: 300;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: $textColor;
}

.ant-tabs-tab {
  font-size: 16px;
  padding: 8px 16px;
  user-select: none;

  &:hover {
    color: $primary;

    .ant-tabs-tab-btn {
      color: $primary;
    }
  }

  & + .ant-tabs-tab {
    margin-left: 0;
  }

  &.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: $primary;
    }
  }
}

.ant-tabs-ink-bar {
  background-color: $primary;
}
