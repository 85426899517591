.course-popover-content {
  display: flex;

  .item {
    display: flex;
    margin-bottom: 5px;

    .title {
      margin-right: 10px;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;

    a {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.fc.fc-theme-standard a.fc-daygrid-event {
  display: inline-flex;
}
